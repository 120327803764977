<template>
   <div>
      <!-- -->

      <el-date-picker v-model="AssessmentReport.from"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择开始日期">
      </el-date-picker>
      <el-date-picker v-model="AssessmentReport.to"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择结束日期">
      </el-date-picker>
      <el-input placeholder="请输入患者姓名"
                suffix-icon="el-icon-search"
                v-model="AssessmentReport.findKey">
      </el-input>
      <el-select v-model="etId"
                 placeholder="请选择需要查看的量表"
                 :clearable="true">
         <el-option value="睡眠觉醒MEQ">睡眠觉醒MEQ</el-option>
         <el-option value="抑郁量表HAMD">抑郁量表HAMD</el-option>
         <el-option value="焦虑量表BAI">焦虑量表BAI</el-option>
         <el-option value="睡眠质量量表PSQI">睡眠质量量表PSQI</el-option>
         <el-option value="综合评估报告">综合评估报告</el-option>
         <el-option value="抑郁症筛查量表PHQ">抑郁症筛查量表PHQ</el-option>
         <el-option value="产后抑郁量表EPDS">产后抑郁量表EPDS</el-option>
         <el-option value="老年抑郁量表GDS">老年抑郁量表GDS</el-option>
         <el-option value="嗜睡量表ESS">嗜睡量表ESS</el-option>
         <el-option value="生活质量量表QOL">生活质量量表QOL-AD</el-option>
         <el-option value="脑震荡后综合征量表RPQ">脑震荡后综合征量表RPQ</el-option>
         <el-option value="躁动行为量表CMAI">躁动行为量表CMAI</el-option>
      </el-select>
      <el-button @click="search"
                 type="primary">查询</el-button>

      <div></div>
      <el-table :data="reverseSum"
                border
                style="width: 100%"
                @sort-change="onSortChange">
         <el-table-column prop="cname"
                          label="患者姓名"
                          align="center"
                          width="150">
         </el-table-column>
         <el-table-column prop="etId"
                          label="评估编号"
                          align="center"
                          width="100">
         </el-table-column>
         <el-table-column prop="etName"
                          label="评估模块"
                          align="center"
                          width="170">
         </el-table-column>
         <el-table-column prop="dateTime"
                          label="评估时间"
                          align="center"
                          width="200"
                          sortable="custom"
                          :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope">{{
          scope.row.dateTime | formatDate
        }}</template>
         </el-table-column>
         <el-table-column label="评估分数"
                          align="center"
                          width="150">
            <template slot-scope="scope">{{ scope.row.score }}</template>
         </el-table-column>
         <el-table-column label="分数结果"
                          prop="solution"
                          align="center"
                          width="150"
                          :show-overflow-tooltip="true">
         </el-table-column>

         <el-table-column label="身份证号"
                          align="center"
                          width="220"><template slot-scope="scope">{{
          scope.row.cid | hideMiddle
        }}</template>
         </el-table-column>
         <el-table-column prop="review"
                          label="治疗建议"
                          align="center"
                          width="150"
                          :show-overflow-tooltip="true">
         </el-table-column>
         <el-table-column fixed="right"
                          label="操作"
                          align="center">
            <template slot-scope="scope">
               <el-button type="success"
                          size="mini"
                          icon="el-icon-edit"
                          @click="
              toUpdateReport(
                scope.row.cid,
                scope.row.id,
                scope.row.etId,
                scope.row.cname
              )
            ">修改报告</el-button>
               <el-button type="primary"
                          size="mini"
                          icon="el-icon-view"
                          @click="
              toReport(
                scope.row.cid,
                scope.row.id,
                scope.row.etId,
                scope.row.cname
              )
            ">查看</el-button>
               <el-button @click="delBnt(scope.row.id)"
                          size="mini"
                          type="danger"
                          icon="el-icon-circle-close">删除</el-button>
            </template>
         </el-table-column>
      </el-table>
   </div>
</template>

<script>
import { formatDate } from "../common/date"; // 在组件中引用date.js
// import { Message } from "element-ui";
import api from "../http/api";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("patient");
export default {
   name: "LookRport",
   data () {
      return {
         // n_solution: "",
         // curretn: "所有报告",
         findKey: "",
         value1: "",
         value2: "",
         searchData: [],
         dialogVisible2: false,
         etId: "",
         // handleClose: false,
         // 存放修改报告数据
         update: {},


      };
   },

   created () {
      this.search()

      // console.log("时间", this.time);
   },
   updated () {

      this.AssessmentReport

   },
   mounted () {
      // this.AssessmentReport
   },
   computed: {
      ...mapState(["AssessmentReport", 'AssessmentData']),
      reverseSum () {
         return this.fliterData2.reverse();
      },
      etNames () {
         if (this.etId == "睡眠觉醒MEQ") {
            return "E1";
         }
         if (this.etId == "抑郁量表HAMD") {
            return "E2";
         }
         if (this.etId == "抑郁症筛查量表PHQ") {
            return "E3";
         }
         if (this.etId == "产后抑郁量表EPDS") {
            return "E4";
         }
         if (this.etId == "老年抑郁量表GDS") {
            return "E5";
         }
         if (this.etId == "综合评估报告") {
            return "E6";
         }

         if (this.etId == "焦虑量表BAI") {
            return "E7";
         }
         if (this.etId == "嗜睡量表ESS") {
            return "E8";
         }
         if (this.etId == "生活质量量表QOL") {
            return "E9";
         }
         if (this.etId == "脑震荡后综合征量表RPQ") {
            return "E10";
         }
         if (this.etId == "躁动行为量表CMAI") {
            return "E11";
         }
         if (this.etId == "睡眠质量量表PSQI") {
            return "E12";
         }
      },
      fliterData2 () {
         if (this.etNames == null) {
            return this.AssessmentData;
         } else if (this.etNames == "E1") {
            return this.AssessmentData.filter((item) => item.etId == "E1");
         } else if (this.etNames == "E2") {
            return this.AssessmentData.filter((item) => item.etId == "E2");
         } else if (this.etNames == "E3") {
            return this.AssessmentData.filter((item) => item.etId == "E3");
         } else if (this.etNames == "E4") {
            return this.AssessmentData.filter((item) => item.etId == "E4");
         } else if (this.etNames == "E5") {
            return this.AssessmentData.filter((item) => item.etId == "E5");
         } else if (this.etNames == "E6") {
            return this.AssessmentData.filter((item) => item.etId == "E6");
         } else if (this.etNames == "E7") {
            return this.AssessmentData.filter((item) => item.etId == "E7");
         } else if (this.etNames == "E8") {
            return this.AssessmentData.filter((item) => item.etId == "E8");
         } else if (this.etNames == "E9") {
            return this.AssessmentData.filter((item) => item.etId == "E9");
         } else if (this.etNames == "E10") {
            return this.AssessmentData.filter((item) => item.etId == "E10");
         } else if (this.etNames == "E11") {
            return this.AssessmentData.filter((item) => item.etId == "E11");
         } else if (this.etNames == "E12") {
            return this.AssessmentData.filter((item) => item.etId == "E12");
         }
      },
   },
   filters: {
      hideMiddle (val) {
         return `${val.substring(0, 3)}****${val.substring(val.length - 3)}`;
      },
      formatDate (time) {
         let date = new Date(time);
         return formatDate(date, "yyyy-MM-dd-hh:mm:ss");
      },
   },
   methods: {
      ...mapActions(["lookRport", "updateReport", "delRport"]),
      // 通过关键字查询患者
      async search () {
         // const data = await api.patient.lookRport({
         //    from: this.value1,
         //    to: this.value2,
         //    key: this.findKey,
         // });
         // if (data.status == 200) {
         //    this.searchData = data.data.data;
         //    console.log("查询数据", this.searchData);
         // }
         this.lookRport()
      },
      /**
       * 表格排序事件处理函数
       * @param {object} {column,prop,order} 列数据|排序字段|排序方式
       */
      onSortChange ({ prop, order }) {
         this.searchData.sort(this.compare(prop, order));
      },

      /**
       * 排序比较
       * @param {string} propertyName 排序的属性名
       * @param {string} sort ascending(升序)/descending(降序)
       * @return {function}
       */
      compare (propertyName, sort) {
         return function (obj1, obj2) {
            var value1 = obj1[propertyName];
            var value2 = obj2[propertyName];
            if (typeof value1 === "string" && typeof value2 === "string") {
               const res = value1.localeCompare(value2, "zh");
               return sort === "ascending" ? res : -res;
            } else {
               if (value1 <= value2) {
                  return sort === "ascending" ? -1 : 1;
               } else if (value1 > value2) {
                  return sort === "ascending" ? 1 : -1;
               }
            }
         };
      },
      // 筛选去对应的量表报告修改界面
      toUpdateReport (cname, id, etId) {
         // 判断ctid是否为E6是的话去E6的综合报告修改界面
         if (etId == "E6") {
            this.$router.push(`/ReportUpdate/${cname}/${id}`);
         }
         // 判断ctid是否为E6不是的话去对应量表的报告修改界面
         if (etId != "E6") {
            this.$router.push(`/ReportUpdate2/${cname}/${id}`);
         }
      },

      toReport (cname, id, etId) {
         // 判断ctid是否为E6是的话去E6的综合报告
         if (etId == "E6") {
            this.$router.push(`/OneReport/${cname}/${id}`);
         }
         // 判断ctid是否为E6不是的话去对应量表的报告
         if (etId != "E6") {
            this.$router.push(`/Correspondence_Report/${cname}/${id}`);
         }
      },
      // // 删除报告
      delBnt (id) {
         this.lookRport()
         // 删除提示框
         this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "danger",
         })
            .then(() => {
               this.$message({
                  type: "success",
                  message: "删除成功！",
               });
               this.delRport(id);
               this.lookRport()

            })
            .catch(() => {
               this.$message({
                  type: "info",
                  message: "已取消删除",
               });
            });
      },
   },
};
</script>

<style lang="scss" scoped>
.el-input {
   width: 200px;
}
</style>
